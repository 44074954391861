<template>
  <div>
    <b-modal
      id="essilor-rooms-modal"
      centered
      hide-header
      hide-footer
    >
      <div class="header">
        <div class="header-content">
          <p>Iniciar exames</p>
          <Close class="close-svg" @click="closeModal" />
        </div>
      </div>
      <div class="body">
        <p>
          Selecione abaixo a sala que deseja realizar o(s) exame(s):
        </p>
        <multiselect
          v-model="selectedRoom"
          track-by="roomId"
          label="roomName"
          :options="roomsOptions"
          :close-on-select="true"
          :option-height="40"
          :showLabels="false"
          :searchable="true"
          :multiple="false"
          placeholder=""
          class="with-border mt-3"
          openDirection="bottom"
        >
          <template #caret>
            <div class="d-flex inline-flex">
              <ChevronDown class="chevron" />
            </div>
            <div class="clear">
              <Close v-if="selectedRoom" @click="clearSelection" class="icon close" />
            </div>
          </template>
        </multiselect>
      </div>

      <div class="actions">
        <b-button
          class="cancel-button"
          variant="outline-danger"
          @click="closeModal"
        >
          Voltar
        </b-button>
        <b-button
          variant="primary"
          @click="onConfirm"
        >
          Confirmar
        </b-button>
      </div>
    </b-modal>
    <ConfirmModal
      title="Aviso"
      body="A sala selecionada possui um paciente com exame(s) ainda não concluído(s). Essa ação é irreversível e resultará na perda das informações."
      description="Deseja realmente substituir esse paciente na sala?"
      confirm-button-text="Confirmar"
      cancel-button-text="Voltar"
      :confirmed="replaceConfirmed"
    />
  </div>
</template>
<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'EssilorRoomsModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    ConfirmModal: () => import('@/components/General/ConfirmModal')
  },
  props: {
    startEssilorAttendance: Function
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      roomsOptions: [],
      selectedRoom: null
    }
  },
  async mounted() {
    await this.getRoomsOptions()
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('essilor-rooms-modal')
    },
    async getRoomsOptions() {
      this.api.getEssilorRoomsByClinic(this.clinic.id)
        .then(response => {
          this.roomsOptions = response.data.rooms
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    async onConfirm() {
      const loading = this.$loading.show()
      const response = await this.api.essilorCheckFreeRoom(this.clinic.id, this.selectedRoom.roomId)
      loading.hide()
      if (response.data?.is_free) {
        this.$bvModal.hide('essilor-rooms-modal')
        this.startEssilorAttendance(this.selectedRoom)
      } else {
        this.$bvModal.show('confirm-modal')
      }
    },
    replaceConfirmed(confirmed) {
      if (confirmed) {
        this.startEssilorAttendance(this.selectedRoom)
      }
      this.$bvModal.hide('confirm-modal')
    },
    clearSelection() {
      this.selectedRoom = null
    }
  }
}
</script>

<style lang="scss" scoped>
.is-disabled {
  color: var(--type-placeholder);
  cursor: not-allowed;
}

.header-content {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--type-active);
}

.close-svg {
  height: 24px;
  width: 24px;
  fill: var(--neutral-500);
}

.chevron {
  height: 24px;
  width: 24px;
}

.clear {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  top: 0;
  right: 10px;
  z-index: 10;
  padding-right: 30px;

  .icon {
    width: 20px;
    height: 20px;
    fill: var(--blue-500);
    cursor: pointer;

    &.close {
      fill: var(--type-active);
    }
  }
}

.modal-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 600px;

  .modal-body {
    padding: 0;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--type-active);

    .header {
      border-bottom: 1px solid var(--neutral-200);

      .header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 24px;
      }
    }

    .body {
      padding: 24px;
      color: var(--type-active);
      font-size: 16px;
      font-weight: 400;
    }

    .actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      padding: 24px;

      button {
        margin-left: 10px !important;
      }

      .cancel-button {
        font-weight: bold !important;
        border-color: transparent !important;
      }
    }
  }
}
</style>